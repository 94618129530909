import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["product", "research"],
  "status": ["completed"],
  "title": "Living Inside a Climate Shell",
  "subtitle": "",
  "description": "",
  "years": ["2014"],
  "institutions": ["aau-cph", "dome of visions", "friland"],
  "location": "cph",
  "keywords": {
    "topics": [],
    "tools": []
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer"
  }, {
    "name": "Nanna",
    "role": "Designer"
  }, {
    "name": "Kirsten Lynge",
    "role": "Designer"
  }, {
    "name": "Gunnar",
    "role": "Designer"
  }, {
    "name": "Simon",
    "role": "Designer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # Living Inside a Climate Shell */
    }
    <h2>{`Description`}</h2>
    <p>{`A project is Mellemrummet, translated into the rooms between inside and outside.`}</p>
    <p>{`The space inside climatic shells where researched in order to advance their presence in society.`}</p>
    <p>{`As they are great ways to decrease energy consumption of indoor heating. And additionally providing a space for different opportunity for activities such as gardening.`}</p>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Zine (Danish)`}</li>
      <li parentName="ul">{`Report`}</li>
      <li parentName="ul">{`Images`}</li>
      <li parentName="ul">{`Concept drawing`}</li>
    </ul>
    <p>{`A research on the thirds space`}</p>
    <h1>{`Mellemrummet`}</h1>
    <h1>{`Flyer`}</h1>
    <p><a parentName="p" {...{
        "href": "https://dk.newsner.com/familie/familien-ville-have-det-varmt-aret-rundt-nu-bor-de-i-gigantisk-drivhus/"
      }}>{`https://dk.newsner.com/familie/familien-ville-have-det-varmt-aret-rundt-nu-bor-de-i-gigantisk-drivhus/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      